import React, { useContext } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"

import css from "./Header.module.css"
import logo from "../images/logo.png"
import { IdentityContext } from "./IdentityProvider"
import Cart from "./Cart"

import PowerIcon from "react-feather/dist/icons/power"

function Header(props) {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery1 {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const { user, logout } = useContext(IdentityContext)

  return (
    <header className={css.header}>
      <nav>
        <Link to="/" className={css.title}>
          <img src={logo} alt={data.site.siteMetadata.title} />
        </Link>
        <div className={css.links}>
          {user && (
            <>
              {user.user_metadata.full_name}
              <PowerIcon
                onClick={() => logout()}
                size="18"
                style={{
                  display: "inline-block",
                  marginLeft: "0.5rem",
                  color: "red",
                  cursor: "pointer",
                  verticalAlign: "text-bottom",
                }}
                title="Logout"
              />
            </>
          )}
          {props.cart && <Cart />}
        </div>
      </nav>
    </header>
  )
}

Header.propTypes = { cart: PropTypes.bool.isRequired }

export default Header
