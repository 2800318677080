import React, { useContext, useState } from "react"
import PropTypes from "prop-types"
import { navigate, Link as BaseLink } from "gatsby"
import { useForm, useFieldArray } from "react-hook-form"
import { TagBox } from "react-tag-box"

import { StripeProductsContext } from "./StripeProductsProvider"
import ProductFormPrices from "./ProductFormPrices"
import ProductFormImages from "./ProductFormImages"
import tw from "twin.macro"
import styled from "styled-components"
import { css } from "styled-components/macro" //eslint-disable-line
import { PrimaryButton } from "../treact/misc/Buttons"
import { PrimaryLink } from "../treact/misc/Links"
import PlusSquareIcon from "react-feather/dist/icons/plus-square"
import TrashIcon from "react-feather/dist/icons/trash-2"

const Button = tw(PrimaryButton)`text-sm`
const DangerLink = tw(
  PrimaryLink
)`px-8 py-3 text-red-700 hocus:(border-red-700 text-red-800)`
const OutlineButton = tw(
  PrimaryButton
)`text-sm border-2 border-primary-700 bg-gray-100 text-primary-700`
const LinkButton = tw(
  BaseLink
)`inline-block px-8 py-3 font-bold text-sm border-2 border-primary-700 bg-gray-100 text-primary-700 rounded-default`
const Toolbar = tw.div`max-w-screen-md flex justify-between items-center mt-8 mx-auto py-2 px-4`
const Title = tw.h2`text-2xl font-bold mt-4`
const FormField = tw.div`flex flex-col sm:flex-row sm:px-2 py-2 justify-end`
const Label = tw.label`sm:w-32 sm:text-right pt-2 pr-4`
const Field = tw.div`flex-1`

const ProductFormContainer = styled.div`
  ${tw`flex flex-col min-h-144 max-w-screen-md my-2 mx-auto py-0 px-4`}

  input, textarea {
    ${tw`border-2 border-gray-300 rounded-xl px-4 py-2 w-full hocus:(outline-none border-primary-400)`}
  }

  label {
    ${tw`tracking-wide font-semibold`}

    input, textarea {
      ${tw`mt-2 w-144 text-lg`}
    }
  }

  ul {
    padding-left: 0px;
  }

  li {
    list-style: none;
  }

  ul.tag-box-pills li {
    ${tw`inline-block bg-primary-200 rounded-default mr-3 mt-2 pl-4`}
  }
  ul.tag-box-pills li .remove {
    ${tw`ml-3 bg-primary-700 text-white`}
  }

  ul.tag-box-pills li button {
    padding: 0.25rem 0.75rem;
  }
  ul.tag-box-pills li.system {
    background-color: chocolate;
  }

  ul.autocomplete {
  }
  ul.autocomplete li {
    cursor: pointer;
    ${tw`cursor-pointer p-2`}
  }
  ul.autocomplete li.considering {
    ${tw`bg-primary-100`}
  }
  ul.autocomplete li .option-text {
    padding-left: 0.5rem;
  }

  .tag-box input {
    ${tw`border-2 border-gray-300 rounded-xl px-4 my-2 w-full hocus:(outline-none border-primary-400)`}
  }
`
const defaultColors = ["white", "beige", "gray", "black", "ivory"].map(c => ({
  label: c,
  value: c,
}))
const defaultMeasurements = [
  "bust",
  "underbust",
  "waist",
  "hips",
  "length of top",
  "sleeve length",
  "width",
  "height",
  "depth",
  "chain",
].map(c => ({
  label: c,
  value: c,
}))
const getMetadataTags = (product, propName) => {
  return (product.metadata[propName] ?? "")
    .split(",")
    .map(s => s.trim())
    .filter(s => s)
    .map(s => ({
      label: s,
      value: s,
    }))
}
export const ProductForm = ({ product, create }) => {
  const { fetchProducts } = useContext(StripeProductsContext)
  const [productImages, setProductImages] = useState(product.images)
  const [colors, setColors] = useState(
    product.metadata ? getMetadataTags(product, "colors") : defaultColors
  )
  const [measurements, setMeasurements] = useState(
    product.metadata
      ? getMetadataTags(product, "measurements")
      : defaultMeasurements
  )

  const tagsToString = tags => tags.map(t => t.value).join(", ")

  const pricesToDecimal = (prices = []) => {
    return prices.map(price => ({
      ...price,
      unit_amount: (price.unit_amount / 100).toFixed(2),
    }))
  }

  const pricesToInteger = (prices = []) => {
    return prices.map(price => ({
      ...price,
      unit_amount: parseFloat(price.unit_amount) * 100,
    }))
  }

  const authFetch = (url, options) => {
    const user = JSON.parse(localStorage.getItem("gotrue.user"))
    const headers = { Authorization: `Bearer ${user.token.access_token}` }
    return fetch(url, {
      ...options,
      headers,
    })
  }

  const onColorSelect = ({ label }) => {
    setColors([...colors, { label, value: label }])
  }

  const removeColor = ({ value }) => {
    setColors(colors.filter(t => t.value !== value))
  }

  const onMeasurementSelect = ({ label }) => {
    setMeasurements([...measurements, { label, value: label }])
  }

  const removeMeasurement = ({ value }) => {
    setMeasurements(measurements.filter(t => t.value !== value))
  }

  const onDelete = async e => {
    e.preventDefault()
    await authFetch(`/.netlify/functions/productDelete`, {
      method: "DELETE",
      body: JSON.stringify({ productId: product.id }),
    })
      .then()
      .then(r => {
        if (r.status !== 200) {
          return r.text()
        } else {
          fetchProducts()
          alert("Product deleted.")
          navigate("/admin")
        }
      })
      .then(t => alert(`ERROR\n${t}`))
      .catch(error => {
        console.log(error)
      })
  }

  const onSubmit = async data => {
    const body = JSON.stringify({
      product: {
        ...data.product,
        images: productImages,
        metadata: {
          colors: tagsToString(colors),
          measurements: tagsToString(measurements),
        },
      },
      prices: pricesToInteger(data.prices),
      productId: product.id,
    })
    const path = create ? "productCreate" : "productUpdate"
    await authFetch(`/.netlify/functions/${path}`, {
      method: "POST",
      body,
    })
      .then(() => {
        fetchProducts()
        alert("Product saved.")
        navigate("/admin")
      })
      .catch(error => {
        console.log(error)
      })
  }

  const { register, handleSubmit, control, watch, getValues } = useForm({
    defaultValues: {
      product,
      prices: pricesToDecimal(product.prices),
    },
  })

  const pricesFieldArray = useFieldArray({
    control,
    name: "prices",
    keyName: "fieldId",
  })

  return (
    <>
      <ProductFormContainer>
        <div tw="flex-1 flex flex-col">
          <Title>{product.id ? "Update" : "Create"} Product</Title>
          <FormField>
            <Label>Active</Label>
            <Field>
              <input
                type="checkbox"
                ref={register()}
                name="product.active"
                tw="w-6! mt-3"
              />{" "}
            </Field>
          </FormField>
          <FormField>
            <Label>Name</Label>
            <Field>
              <input
                ref={register({ required: true, max: 5000 })}
                name="product.name"
                type="text"
              />
            </Field>
          </FormField>
          <FormField>
            <Label>Description</Label>
            <Field>
              <textarea
                ref={register({ max: 5000 })}
                name="product.description"
                cols="30"
                rows="5"
              />
            </Field>
          </FormField>
          <FormField>
            <Label>Prices</Label>
            <Field>
              <ProductFormPrices
                pricesFieldArray={pricesFieldArray}
                register={register}
                getValues={getValues}
                watch={watch}
              ></ProductFormPrices>
              <div>
                <OutlineButton
                  onClick={() => pricesFieldArray.append({ active: true })}
                  tw="py-1 px-2 mt-2"
                >
                  <PlusSquareIcon tw="inline-block align-text-bottom" /> Add
                  Price
                </OutlineButton>
              </div>
            </Field>
          </FormField>
          <FormField>
            <Label>Colors</Label>
            <Field>
              <TagBox
                tags={defaultColors}
                selected={colors}
                onSelect={onColorSelect}
                removeTag={removeColor}
                backspaceDelete={true}
              />
            </Field>
          </FormField>
          <FormField>
            <Label>Measurements</Label>
            <Field>
              <TagBox
                tags={defaultMeasurements}
                selected={measurements}
                onSelect={onMeasurementSelect}
                removeTag={removeMeasurement}
                backspaceDelete={true}
              />
            </Field>
          </FormField>
          <FormField>
            <Label>Images</Label>
            <Field>
              <ProductFormImages
                productImages={productImages}
                setProductImages={setProductImages}
              ></ProductFormImages>
            </Field>
          </FormField>
        </div>
      </ProductFormContainer>
      <Toolbar>
        {product.id && (
          <DangerLink onClick={onDelete}>
            <TrashIcon tw="inline-block align-text-bottom mr-2" />
            Delete
          </DangerLink>
        )}
        <div>
          <LinkButton to="/admin" tw="mr-2">
            Cancel
          </LinkButton>
          <Button onClick={handleSubmit(onSubmit)}>Save</Button>
        </div>
      </Toolbar>
    </>
  )
}

ProductForm.propTypes = {
  product: PropTypes.object.isRequired,
  create: PropTypes.bool.isRequired,
}

export default ProductForm
