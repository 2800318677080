import React, { useContext } from "react"
import { Link as BaseLink } from "gatsby"
import styled from "styled-components"
import tw from "twin.macro"
import { css } from "styled-components/macro" //eslint-disable-line
import { PrimaryButton } from "../treact/misc/Buttons"
import UploadCloudIcon from "react-feather/dist/icons/upload-cloud"
import SquareIcon from "react-feather/dist/icons/square"
import CheckSquareIcon from "react-feather/dist/icons/check-square"

import { StripeProductsContext } from "./StripeProductsProvider"

const OutlineButton = tw(
  PrimaryButton
)`text-sm border-2 border-primary-700 bg-gray-100 text-primary-700 mx-4`
const Link = tw(BaseLink)`text-primary-600 hocus:text-primary-700`
const LinkButton = tw(
  BaseLink
)`inline-block px-8 py-3 font-bold text-beige-100 bg-primary-600 rounded-default`
const Toolbar = tw.div`text-right px-0 pt-2 pb-4`
const ListContainer = styled.div`
  ${tw`max-w-screen-lg my-2 mx-auto py-0 px-4`}

  table {
    border-collapse: collapse;
    overflow: hidden;
    border-style: hidden;
    width: 100%;
    white-space: nowrap;
    ${tw`rounded-lg`}

    thead th {
      ${tw`px-2 py-3 text-beige-100 bg-beige-700 text-left font-medium`}
    }

    td {
      ${tw`p-2 text-gray-600`}
      border-right: 1px solid #f8f8f8;
    }

    tr {
      ${tw`bg-gray-100 shadow-raised border-4 border-white`}
    }

    tr:nth-child(even) {
      ${tw`bg-gray-200`}
    }
  }
`

export const ProductList = () => {
  const { listProducts } = useContext(StripeProductsContext)
  const products = listProducts()

  const onRedeploy = async e => {
    e.preventDefault()
    return fetch(
      "https://api.netlify.com/build_hooks/6153e54ed2b5f6722fae78dc",
      { method: "POST" }
    )
  }
  return (
    <ListContainer>
      <Toolbar>
        <OutlineButton onClick={onRedeploy} tw="align-bottom">
          <UploadCloudIcon size="18" />
        </OutlineButton>
        <LinkButton to="/admin/products/create">+ Add Product</LinkButton>
      </Toolbar>
      <table>
        <thead>
          <tr>
            <th tw="max-w-1 text-center!">Active</th>
            <th>Name</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          {products.map(({ id, name, prices, active }) => (
            <React.Fragment key={id}>
              <tr>
                <td tw="text-center">
                  { active ? <CheckSquareIcon tw="mx-auto text-primary-700" size="18" /> : <SquareIcon tw="mx-auto" size="18" />}
                </td>
                <td>
                  <Link to={`/admin/products/${id}`} tw="font-semibold">
                    {name}
                  </Link>
                </td>
                <td>
                  {prices.length === 1
                    ? "$" + (prices[0].unit_amount / 100).toFixed(2)
                    : "-"}
                </td>
              </tr>
              {prices.length > 1 &&
                prices.map(
                  ({ id, unit_amount: unitAmount, nickname, active }) => (
                    <tr key={id}>
                      <td>
                      { active ? <CheckSquareIcon tw="mx-auto text-primary-700" size="18" /> : <SquareIcon tw="mx-auto" size="18" />}
                        <span>{nickname}</span>
                      </td>
                      <td>{"$" + (unitAmount / 100).toFixed(2)}</td>
                    </tr>
                  )
                )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </ListContainer>
  )
}

export default ProductList
