import React, { useEffect } from "react"
import useDrivePicker from "react-google-drive-picker"
import PropTypes from "prop-types"
import tw from "twin.macro"
import styled from "styled-components"
import { css } from "styled-components/macro" //eslint-disable-line
import { PrimaryButton } from "../treact/misc/Buttons"
import PlusSquareIcon from "react-feather/dist/icons/plus-square"

const OutlineButton = tw(
  PrimaryButton
)`text-sm border-2 border-primary-700 bg-gray-100 text-primary-700`

function ProductFormImages({ productImages, setProductImages }) {
  const [openPicker, data, authResponse] = useDrivePicker()
  // const customViewsArray = [new google.picker.DocsView()]; // custom view
  const handleOpenPicker = () => {
    openPicker({
      clientId:
        "692967791339-a92h3po4jd5um69t5c1jm2tpev04nt5u.apps.googleusercontent.com",
      developerKey: "AIzaSyCMBKq7tJqgBONa3_fDH1XBCcxRPzoxN00",
      viewId: "DOCS_IMAGES",
      setSelectFolderEnabled: true,
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      customScopes: ["https://www.googleapis.com/auth/drive.file"],
      setParentFolder: "1MIyd_FTl4NGmD8NNOG8oT3QF7EMW53RI",
      // customViews: customViewsArray, // custom view
    })
  }

  useEffect(() => {
    // do anything with the selected/uploaded files
    if (data) {
      const newImages = data.docs.map(
        i => `https://drive.google.com/uc?id=${i.id}`
      )
      const allImages = new Set([...productImages, ...newImages])
      setProductImages([...allImages])
    }
  }, [data])

  // const add = src => setProductImages([...productImages, src])
  const remove = src => setProductImages(productImages.filter(x => x !== src))

  // const input = useRef()

  return (
    <>
      <div tw="flex flex-wrap content-center">
        {productImages.map(src => (
          <div
            tw="border-2 border-gray-300 relative max-w-40 h-40 m-2"
            key={src}
          >
            <button
              onClick={() => remove(src)}
              tw="absolute h-8 w-8 top-0 right-0 bg-gray-lightest opacity-50 hocus:opacity-100"
            >
              &times;
            </button>
            <img
              src={src}
              alt={"Could not load image"}
              tw="w-full h-full object-contain  object-center p-1 my-0 mx-auto"
            />
          </div>
        ))}
      </div>
      <div>
        <OutlineButton onClick={() => handleOpenPicker()} tw="py-1 px-2 mt-2">
          <PlusSquareIcon tw="inline-block align-text-bottom" /> Add Images
        </OutlineButton>
      </div>
    </>
  )
}

ProductFormImages.propTypes = {
  productImages: PropTypes.array.isRequired,
  setProductImages: PropTypes.func.isRequired,
}

export default ProductFormImages
