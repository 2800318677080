import React from "react"
import PropTypes from "prop-types"

// import "../hiq.css"
import Header from "../Header"
import StripeProductsProvider from "./StripeProductsProvider"

import { Container, ContentXl } from "../treact/misc/Layouts"

const Layout = ({ children }) => (
  <StripeProductsProvider>
    <Header cart={false}></Header>
    <Container style={{backgroundColor: 'white'}}>
      <ContentXl>
        <main>{children}</main>
      </ContentXl>
    </Container>
  </StripeProductsProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
